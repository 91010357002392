.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 10px !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 12px !important;
}

.align-container {
    display: grid;
    grid-template-columns: 1fr auto;
    /* Left takes remaining space, right adjusts to content */
    align-items: center;
    /* Vertically aligns items */
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px !important
}

.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-size: 0.6rem !important;
}

.align-left,
.align-right {
    padding: 10px;
}

.hide {
    display: none;
}

.display-block {
    display: block;
}

.dashboard-page {
    position: relative;
}

.dashboard-page .content {
    position: relative;
    margin-left: 309px;
    margin-top: 9px;
}

.dashboard-page .content .dashboard-area {
    padding: 25px 100px 20px;
}

.dashboard-page .content .title-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.dashboard-page .content .title-area h2 {
    margin-bottom: 0;
    font-size: 26px;
    font-weight: 600;
    color: #01194d;
}

.title-area h2 {
    margin-bottom: 0;
    font-size: 26px;
    font-weight: 600;
    color: #01194d;
}

.dashboard-page .content .dashboard-area .content-box {
    position: relative;
    padding-top: 22px;
}

.dashboard-page .content .dashboard-area .content-box h3 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 10px;
}

.dashboard-page .content .dashboard-area .content-box p {
    font-size: 16px;
    margin-bottom: 0;
    color: #01194d;
}

.text-simple {
    font-size: 14px !important;
    color: #212122 !important;
}

.dashboard-page .content .dashboard-area .content-box p .icon {
    display: inline-block;
    width: 23px;
    margin-left: 5px;
}


.dashboard-page .content .dashboard-area .content-box .table-area {
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table {
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table thead tr th {
    border-top: none;
    border-bottom: 1px solid #eff4ff;
    font-size: 16px;
    color: #1f3462;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td {
    border-bottom: 1px solid #eff4ff;
    font-size: 15px;
    color: #1f3462;
    vertical-align: middle;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td .edit-btn {
    border: none;
    background-color: transparent;
    font-size: 16px;
    color: #0065ff;
    font-weight: 500;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td .edit-btn .icon {
    width: 17px;
    display: inline-block;
    margin-right: 4px;
    position: relative;
    top: -1px;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td input {
    border: 1px solid #eff4ff;
    padding: 10px;
    font-size: 14px;
    color: #1f3462;
    border-radius: 5px;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td input::placeholder {
    color: #1f346290;
}

.dashboard-page .content .dashboard-area .content-box .title-area.overview-title h5 {
    font-weight: 600;
    font-size: 18px;
}

.dashboard-page .content .dashboard-area .content-box .title-area.overview-title .btn-style-two {
    font-size: 14px;
}

.dashboard-page .content .dashboard-area .content-box .title-area.overview-title h5 {
    font-weight: 600;
    font-size: 18px;
}

.dashboard-page .content .dashboard-area .content-box .title-area.overview-title .btn-style-two {
    font-size: 14px;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td .company-data {
    display: flex;
    align-items: center;
    position: relative;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td .company-data .icon {
    display: inline-block;
    width: 16px;
    margin-right: 9px;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td .company-data h4 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td a {
    margin-left: 24px;
    font-size: 14px;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td .without-icon {
    margin-left: 0;
    color: #0065ff !important;
    border: none;
    background-color: transparent;
    font-size: 14px;
}

.dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td .company-data .icon-circle {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-right: 9px;
}

.In-Progress {
    background-color: #ffdc00;
}

.Completed {
    background-color: #ccff00;
}

.Stopped {
    background-color: #ff7a0e;
}

.Drafted {
    background-color: #858585;
}

.modal-migrationDetail {
    max-width: 86% !important;
    margin: 1.75rem auto;
}

.modal-migrationDetail .modal-header {
    border: none;
    padding: 34px 45px 0;
}

.modal-migrationDetail .modal-header h5.modal-title {
    font-size: 22px !important;
}

.modal-migrationDetail .modal-header h5.modal-title h2 {
    font-size: 23px;
    font-weight: 700;
}

.modal-migrationDetail .modal-header h5.modal-title h2 span {
    font-weight: 400;
}

.modal-migrationDetail .modal-header .close {
    padding: 14px 19px;
    color: #0065ff;
    opacity: 1;
    font-size: 21px;
}

.modal-migrationDetail .modal-body {
    padding: 0;
}

.modal-migrationDetail .modal-body .top-tabs-area {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 45px 15px;
    border-bottom: 1px solid #dfe5f0;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area {
    width: 77%;
}

.modal-migrationDetail .modal-body .top-tabs-area .right-area {
    width: 23%;
    text-align: right;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list {
    margin-bottom: -5px;
    border: none;
    display: flex;
    align-items: center;
    list-style: none;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiFormControl-root {
    padding: 0;
    margin: 0;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiAutocomplete-root {
    padding: 0;
    margin: 0;
    margin-left: -47px;
    margin-top: 4px;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiAutocomplete-root .MuiInputBase-root input {
    padding: 0;
    font-size: 14px;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiAutocomplete-root .MuiInputBase-root {
    border: none;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiAutocomplete-root .MuiInputBase-root fieldset {
    padding: 0;
    margin: 0;
    border: none;
    color: #0065ff;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .react-tabs__tab {
    background-color: transparent;
    border: none;
    color: #0065ff;
    font-size: 16px;
    margin-right: 25px;
    transition: all 0.3s ease-in-out;
    padding: 0;
    cursor: pointer;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiAutocomplete-root .MuiInputBase-root svg,
.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiInputBase-root svg {
    fill: #0065ff;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiFormControl-root label {
    font-size: 15px;
    color: #01194d;
    top: -8px;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiInputBase-root {
    position: relative;
    margin-left: -34px;
    margin-right: 18px;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiInputBase-root div#supported-objects-multiple-checkbox {
    font-size: 14px;
    padding: 0;
    border: none;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiInputBase-root fieldset {
    border: none;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .MuiInputBase-root div#supported-objects-multiple-checkbox em {
    font-style: normal;
    font-size: 15px;
    color: #01194d;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .react-tabs__tab--selected {
    font-weight: 700;
}

.modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .react-tabs__tab:hover {
    text-decoration: underline;
}

.modal-migrationDetail .modal-body .main-content-tabs {
    position: relative;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab {
    position: relative;
    margin: 0;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #fafcff;
    border-bottom: 1px solid #dfe5f0;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area .record-box {
    text-align: center;
    position: relative;
    width: 25%;
    border-right: 1px solid #dfe5f0;
    padding: 21px 30px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area .record-box p {
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 500;
    color: #8894ad;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area .record-box p {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-info {
    display: inline-block;
    width: 18px;
    margin-left: 7px;
    position: relative;
    top: -2px;
}

.icon-info .overlabox-tooltip {
    position: absolute !important;
    left: -30px;
    width: 200px;
    background-color: #fff;
    z-index: 99999;
    padding: 10px;
    text-align: left;
    box-shadow: 0 0 5px rgba(0 0 0 / 20%);
    border-radius: 10px;
    top: -2px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

.icon-info .overlabox-tooltip p {
    display: block;
    font-size: 12px !important;
}

.icon-info:hover .overlabox-tooltip {
    opacity: 1;
    visibility: visible;
}

.icon-info img {
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
}

.icon-info:hover img {
    opacity: 1;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area .record-box h2 {
    margin-bottom: 0;
    font-size: 35px;
    font-weight: 700;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry {
    position: relative;
    padding: 20px 45px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td {
    border-top: none;
    vertical-align: middle;
    padding: 15px 25px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data h5 {
    margin-bottom: 0;
    color: #01194d;
    font-size: 16px;
    font-weight: 700;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .right-btn {
    position: relative;
    display: flex;
    align-items: center;
}

.sample-tag {
    font-size: 12px;
    background: #dfe3f8;
    padding: 10px;
    margin: 20px;
    border-radius: 10px;
    color: #686868;
}

.bottom-note {
    padding: 20px;
    color: #686868;
    font-size: 12px;
    text-align: center;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .right-btn p {
    margin-bottom: 0;
    font-size: 16px;
    color: #0065ff;
    font-weight: 500;
    margin-right: 35px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .right-btn .download-btn {
    background-color: transparent;
    border: none;
    width: 17px;
    position: relative;
    top: -2px;
    margin-left: 10px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table {
    width: 94%;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area {
    position: relative;
    max-height: 66vh;
    overflow-y: auto;
    padding: 0;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .content-box {
    padding: 0;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area {
    position: relative;
    padding: 20px 45px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .content-box .connect-inner {
    padding: 20px 45px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box {
    position: relative;
    display: flex;
    align-items: center;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form {
    position: relative;
    margin-right: 35px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form label {
    display: block;
    width: 100%;
    font-size: 16px;
    margin-bottom: -5px;
    color: #01194d;
}


.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form .MuiInputBase-root .MuiSelect-select {
    padding: 10px 32px 10px 10px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form .MuiInputBase-root fieldset {
    border: 1px solid #dfe5f0;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form input {
    border: 1px solid #dfe5f0;
    padding: 11px 15px;
    font-size: 16px;
    width: 299px;
    border-radius: 5px;
    color: #01194d;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form input::placeholder {
    color: #01194d90;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .btn-style-one {
    margin-top: 37px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area {
    position: relative;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area {
    position: relative;
    padding: 20px 45px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area h4 {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 700;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box {
    position: relative;
    display: flex;
    align-items: center;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form {
    position: relative;
    margin-right: 35px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form label {
    display: block;
    width: 100%;
    font-size: 16px;
    margin-bottom: -5px;
    color: #01194d;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form .MuiInputBase-root .MuiSelect-select {
    padding: 10px 32px 10px 10px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form .MuiInputBase-root fieldset {
    border: 1px solid #dfe5f0;
}


.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .btn-style-one {
    margin-top: 37px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result {
    position: relative;
    padding: 20px 45px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table tbody tr td {
    border-top: none;
    vertical-align: middle;
    font-size: 16px;
    color: #01194d;
    font-weight: 500;
    border-bottom: 1px solid #dfe5f080;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table tbody tr td .open-btn,
.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-paginator .open-btn {
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #0065ff;
    font-weight: 500;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-paginator .open-btn.black {
    color: #000;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-paginator .open-btn.bold {
    font-weight: 700;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-paginator {
    text-align: center;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-paginator .open-btn {
    margin-left: 5px;
    margin-right: 5px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area p {
    margin-bottom: 8px;
    font-size: 14px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area p a {
    font-weight: 500;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area {
    position: relative;
    padding: 10px 45px 30px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dfe5f080;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box h5 {
    position: relative;
    margin-bottom: 19px;
    font-size: 17px;
    font-weight: 700;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box h5 span {
    color: #0065ff;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .btns-area {
    position: relative;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .btns-area .errors-btn {
    margin-right: 15px;
    color: #01194d;
    font-weight: 700;
    font-size: 16px;
    border: none;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .btns-area .errors-btn:hover {
    color: #0065ff;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area p {
    margin-bottom: 8px;
    font-size: 14px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area p a {
    font-weight: 500;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area,
.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .csv-download {
    position: relative;
    padding: 10px 45px 30px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .csv-download {
    text-align: right;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dfe5f080;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box h5 {
    position: relative;
    margin-bottom: 19px;
    font-size: 17px;
    font-weight: 700;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box h5 span {
    color: #0065ff;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .btns-area {
    position: relative;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .btns-area .errors-btn {
    margin-right: 15px;
    color: #01194d;
    font-weight: 700;
    font-size: 16px;
    border: none;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .btns-area .errors-btn:hover {
    color: #0065ff;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .count-text {
    position: relative;
    display: flex;
    margin-bottom: 21px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .count-text span {
    margin-right: 22px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .count-text span {
    width: 100px;
    margin-right: 0;
    font-size: 16px;
    font-weight: 500;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .count-text p {
    margin-bottom: 0;
    font-size: 16px;
}

/* ========== Custom Scroll Styles =========== */

/* width */
.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area::-webkit-scrollbar,
.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area::-webkit-scrollbar-track,
.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area::-webkit-scrollbar-thumb,
.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 20px;
}

/* Handle on hover */
.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area::-webkit-scrollbar-thumb:hover,
.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area::-webkit-scrollbar-thumb:hover {
    background: #e8e8e8;
    border-radius: 20px;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .add-property {
    margin: 0 !important;
}

.dashboard-page .content .dashboard-area .content-box .connect-inner .table-area .table tbody tr td .devider {
    color: #0065ff;
    margin: 0 13px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .progress-bar-circle {
    display: flex;
    align-items: center;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .progress-bar-circle .progress-inner {
    position: relative;
    margin-right: 15px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .progress-bar-circle .progress-inner p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    color: #fff;
    font-weight: 700;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .progress-bar-circle .progress-inner .CircularProgressbar-path {
    stroke: #0065ff;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .progress-bar-circle .right-area p {
    font-size: 13px;
    margin-bottom: 0;
    color: #01194d;
    font-weight: 500;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .progress-bar-circle .CircularProgressbar .CircularProgressbar-trail {
    stroke: #01194d;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .progress-bar-circle.error-circle .CircularProgressbar .CircularProgressbar-trail {
    stroke: #fff;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .progress-bar-circle.error-circle .progress-inner {
    border: 1px solid #ff0000;
    border-radius: 50%;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra p {
    color: #01194D;
    font-size: 16px;
    margin: 15px 0;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area {
    position: relative;
    height: 47vh;
    overflow-y: auto;
    margin-top: 35px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    border-bottom: 1px solid #E8EFFF50;
    padding-bottom: 10px;
    width: 93%;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box .left-box {
    position: relative;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box .left-box h5 {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: #01194D;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box .right-box {
    position: relative;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box .right-box .hubsport-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #0065ff;
    text-decoration: none !important;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box .right-box .hubsport-link .icon {
    margin-right: 13px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box .right-box .hubsport-link .icon img {
    width: 23px;
    border-radius: 3px;
}


.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box.disabled .right-box .hubsport-link {
    pointer-events: none;
    color: #979797 !important;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box.disabled .right-box .hubsport-link .icon img {
    filter: grayscale(1);
    opacity: 0.5;
}


.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .pagination-area {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .pagination-area {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .pagination-area .MuiPagination-root .MuiPagination-ul li button {
    color: #0065FF;
    font-size: 16px;
    border: none !important;
    background-color: transparent;
    margin: 0 20px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .pagination-area .MuiPagination-root .MuiPagination-ul li li {
    margin: 0;
    color: #0065FF;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .pagination-area .MuiPagination-root .MuiPagination-ul li li button{
    margin: 0 5px;
}

.modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .pagination-area .MuiPagination-root .MuiPagination-ul li li button:disabled{
    opacity: 0.38;
}
@media(max-width: 1610px) {
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area{
        height: 43vh;
    }
}
@media(max-width: 1410px) {
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box{
        padding-bottom: 7px;
        margin-bottom: 18px;
    }
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area {
        height: 39vh;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box .left-box h5,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box .right-box .hubsport-link{
        font-size: 14px;
    }
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area.user-extra .user-list-area .userlist-box .right-box .hubsport-link .icon img {
        width: 18px;
    }
}
@media(max-width: 1366px) {

    .dashboard-page .content .dashboard-area .content-box .table-area .table thead tr th,
    .dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td .company-data h4,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data h5 {
        font-size: 14px;
    }

    .dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td,
    .dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td a,
    .dashboard-page .content .dashboard-area .content-box .table-area .table tbody tr td .without-icon,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .right-btn p {
        font-size: 13px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table {
        width: 100%;
    }

    .modal-migrationDetail .modal-body .top-tabs-area .right-area .btn-style-one {
        padding: 8px 15px;
        font-size: 14px;
    }
}

@media(max-width: 1199px) {
    .dashboard-page .content .dashboard-area .content-box .table-area .table-responsive .table {
        width: 800px;
    }

    /* width */
    .dashboard-page .content .dashboard-area .content-box .table-area .table-responsive::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    .dashboard-page .content .dashboard-area .content-box .table-area .table-responsive::-webkit-scrollbar-track {
        background: #fff;
    }

    /* Handle */
    .dashboard-page .content .dashboard-area .content-box .table-area .table-responsive::-webkit-scrollbar-thumb {
        background: #e8e8e8;
        border-radius: 20px;
    }

    /* Handle on hover */
    .dashboard-page .content .dashboard-area .content-box .table-area .table-responsive::-webkit-scrollbar-thumb:hover {
        background: #e8e8e8;
        border-radius: 20px;
    }

    .modal-migrationDetail .modal-body .top-tabs-area .right-area .btn-style-one {
        padding: 8px 12px;
        font-size: 12px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry {
        position: relative;
        padding: 20px 0;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area .record-box h2 {
        font-size: 25px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table tbody tr td,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table tbody tr td .open-btn {
        font-size: 14px;
    }
}

@media(max-width: 991px) {
    .dashboard-page .content {
        margin-left: 64px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td .download-data .right-btn p {
        margin-right: 12px;
    }

    .modal-migrationDetail .modal-body .top-tabs-area .left-area {
        width: 70%;
    }

    .modal-migrationDetail .modal-body .top-tabs-area .right-area {
        width: 30%;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table tbody tr td {
        padding: 15px 28px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form input {
        font-size: 14px;
        width: 240px;
        padding: 9px 15px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .count-text p,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box .btns-area .errors-btn {
        font-size: 14px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area .errors-box h5 {
        font-size: 15px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form .MuiInputBase-root {
        width: 240px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form .MuiInputBase-root .MuiSelect-select {
        padding: 9px 32px 9px 10px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form {
        position: relative;
        margin-right: 15px;
    }
}

@media(max-width: 767px) {
    .modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .react-tabs__tab {
        font-size: 14px;
        margin-right: 25px;
    }

    .modal-migrationDetail .modal-body .top-tabs-area .left-area {
        width: 100%;
        text-align: center;
    }

    .modal-migrationDetail .modal-body .top-tabs-area .right-area {
        width: 100%;
        text-align: center;
        margin-top: 11px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form .MuiInputBase-root,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form input {
        width: 200px;
    }
}

@media(max-width: 680px) {
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table-responsive .table {
        width: 752px;
    }

    /* width */
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table-responsive::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table-responsive::-webkit-scrollbar-track {
        background: #fff;
    }

    /* Handle */
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table-responsive::-webkit-scrollbar-thumb {
        background: #e8e8e8;
        border-radius: 20px;
    }

    /* Handle on hover */
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .table-summry .table-responsive::-webkit-scrollbar-thumb:hover {
        background: #e8e8e8;
        border-radius: 20px;
    }

    .modal-migrationDetail .modal-header {
        border: none;
        padding: 19px 16px 0;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area {
        justify-content: center;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area .record-box {
        width: 50%;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box {
        flex-wrap: wrap;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form {
        position: relative;
        margin-right: 15px;
        width: 45%;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .btn-style-one {
        margin-top: 13px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table tbody tr td,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table tbody tr td .open-btn {
        font-size: 12px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area {
        position: relative;
        padding: 20px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .errors-area {
        padding: 10px 25px 20px;
    }
}

@media(max-width: 600px) {
    .dashboard-page .content .title-area h2 {
        font-size: 20px;
    }

    .dashboard-page .content .dashboard-area .content-box h3 {
        font-size: 18px;
    }

    .dashboard-page .content .dashboard-area .content-box p,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area .record-box p,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form label {
        font-size: 14px;
    }

    .dashboard-page .content .dashboard-area .content-box .MuiButtonBase-root {
        font-size: 13px !important;
        padding: 6px 20px !important;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area .record-box h2 {
        font-size: 20px;
    }

    .modal-migrationDetail {
        max-width: 97% !important;
        margin: 1.75rem auto;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table-responsive .table {
        width: 600px;
    }

    /* width */
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table-responsive::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table-responsive::-webkit-scrollbar-track {
        background: #fff;
    }

    /* Handle */
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table-responsive::-webkit-scrollbar-thumb {
        background: #e8e8e8;
        border-radius: 20px;
    }

    /* Handle on hover */
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .table-result .table-responsive::-webkit-scrollbar-thumb:hover {
        background: #e8e8e8;
        border-radius: 20px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form {
        position: relative;
        margin-right: 0;
        width: 100%;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form .MuiInputBase-root,
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .result-tabs-area .top-form-area .form-box .group-form input {
        width: 100%;
    }
}

@media(max-width: 480px) {
    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area .record-box {
        width: 100%;
        border: none;
    }

    .dashboard-page .content .title-area {
        flex-direction: column;
    }

    .dashboard-page .content .title-area h2 {
        margin-bottom: 0;
    }

    .dashboard-page .content .title-area .btn-style-one {
        margin-top: 20px;
    }

    .dashboard-page .content .dashboard-area .content-box {
        position: relative;
        padding-top: 10px;
    }

    .dashboard-page .content .dashboard-area {
        padding: 31px 20px 20px;
    }

    .modal-migrationDetail .modal-body .top-tabs-area {
        padding: 0 25px 15px;
    }

    .modal-migrationDetail .modal-body .main-content-tabs .summry-tab .record-area {
        padding: 23px;
    }
}

@media(max-width: 380px) {
    .dashboard-page .content {
        margin-left: 48px;
    }

    .modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .react-tabs__tab {
        font-size: 13px;
        margin-right: 16px;
    }

    .modal-migrationDetail .modal-body .top-tabs-area .left-area .react-tabs__tab-list .react-tabs__tab:last-child {
        margin-right: 0px;
    }
}